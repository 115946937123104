/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ContentModule from '../ContentModule';
import ErrorBoundary from '../ErrorBoundary';
import ElementCardListPropTypes from './ElementCardListPropTypes';

const rowsMapping = {
  '5 per row': 5,
  '6 per row': 6
};

const filterSupportedListStyles = ['1 per row', '2 per row', '3 per row', '4 per row'];

const processCards = (cards) => {
  return (
    cards &&
    cards.map((card) => {
      const { _contentTypeId } = card;
      if (_contentTypeId !== 'card') return { ...card, linkedContent: card };
      return card;
    })
  );
};

function ElementCardList(props) {
  const { listStyle, cards, showPagination, showFilter, contentTypeId } = props;
  let variant = listStyle || '3 per row';
  const cardList = processCards(cards);
  const rows = rowsMapping[listStyle] || 1;

  if ((showPagination || showFilter) && filterSupportedListStyles.includes(listStyle)) {
    variant = 'Rows With Filter';
  }

  return (
    <ErrorBoundary>
      <ContentModule
        {...props}
        contentTypeId={contentTypeId ?? 'cardList'}
        cards={cardList}
        rows={rows}
        variant={variant}
      />
    </ErrorBoundary>
  );
}

ElementCardList.propTypes = ElementCardListPropTypes;
ElementCardList.defaultProps = {
  titleModule: null,
  cards: null,
  categories: null,
  pullCardsAutomatically: null,
  numberOfCardsToPull: null,
  link: null,
  colorScheme: null,
  anchor: ''
};

export default ElementCardList;
